import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RideDetailFilterDto } from '../../interfaces/ride-data/ride-list.interface';

@Injectable()
export class RidesService {
  constructor(private http: HttpClient) {}

  public syncRidesListByDriver(driverId: bigint): any {
    return this.http.get(
      `${environment.mainapiUrl}/rides/drivers/${driverId}`,
    );
  }

  public syncRidesListByDriverAndDate(driverId: bigint, dateOfService: string): any {
    return this.http.get(
      `${environment.mainapiUrl}/rides/drivers/${driverId}/date`,
      {
        params: {
          dateOfService,
        },
      }
    );
  }
  
  public getMilesDriven(): any {
    return this.http.get(`${environment.mainapiUrl}/rides/miles-driven`);
  }

  public getEventsStatistics(): any {
    return this.http.get(
      `${environment.mainapiUrl}/rides/events-statistics`,
    );
  }

  public getRideDetails(filter: RideDetailFilterDto): any {
    return this.http.post(
      `${environment.mainapiUrl}/rides/get-details`,
      filter,
    );
  }

  public getRideCoordinates(rideId: bigint): Observable<any> {
    return this.http.get(
      `${environment.mainapiUrl}/rides/${rideId}/coordinates`,
    );
  }
}
